/* jshint ignore:start */

var VideoPlay = (function() {
    var instance;

    function init() {

        var VIMEO_TEMPLATE = '<iframe src="https://player.vimeo.com/video/[*VIDEO_CODE*]?autoplay=true" width="[*WIDTH*]" height="[*HEIGHT*]" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';

        function initVideos() {
            $('.video-play').on('click', showVideo);
        }

        function showVideo (ev) {
            var $video = $(ev.target);
            var iframeStr = VIMEO_TEMPLATE.replace('[*VIDEO_CODE*]', $video.attr('data-id-video'));
            var iframeStr = iframeStr.replace('[*WIDTH*]', $video.outerWidth());
            var iframeStr = iframeStr.replace('[*HEIGHT*]', $video.outerHeight());

            $video.css('background', 'black');

            $video.append(iframeStr);
        }

        return {
            activateVideos: function() {                
                initVideos();
            },
        };
    }


    return {
        getInstance: function() {
            if (!instance) { instance = init(); }
            return instance;
        }
    };


})();
/* jshint ignore:end */
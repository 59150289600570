/**
 *
 * Gestion de carruseles
 *
 */

var CarouselManager = (function(){
    //// console.log("llamada a config idioma");
    var instance;


    function init() {

        return {
            checkAndInit: function(){
                var dots;
                var nav;

                if ($('.js-owl-carousel').length) {

                    var dots = $(this).data('dots');


                    $('.js-owl-carousel').owlCarousel({
                        loop: true,
                        nav: true,
                        dots: false,
                        items: 5,
                        center: false,
                        avRewind: false,
                        lazyLoad: true,
                        center: true,
                        autoplay: true,
                        margin: 0,
                        autoplayTimeout: 5000,
                        autoplayHoverPause: false,
                        smartSpeed: 1000,
                        responsive: {
                            0: {
                                items: 1
                            },
                            600: {
                                items: 2
                            },
                            1000: {
                                items: 5
                            }
                        }
                    })
                }

                // SLIDER HOME
                if (jQuery('.js-owl-carousel-slider-home').length) {

                    jQuery('.js-owl-carousel-slider-home').each(function () {
                        dots = jQuery(this).data('dots');
                        nav = jQuery(this).data('nav');

                        // console.log('nav:'+nav);
                        // console.log('dots:'+dots);

                        jQuery(this).owlCarousel({
                            navRewind: false,
                            lazyLoad: true,
                            center: true,
                            items: 1,
                            loop: true,
                            autoWidth: false,
                            autoplay: true,
                            margin: 0,
                            autoplayTimeout: 5000,
                            autoplayHoverPause: false,
                            smartSpeed : 1000,
                            // animateOut: 'fadeOut',
                            //nav: true,
                            nav: nav,
                            navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
                            //dots: true
                            dots: dots

                        });

                    });
                }

                // carrusel logos
                if ($('.owl-carousel-slider-logos').length) {

                    $('.owl-carousel-slider-logos').owlCarousel({
                        loop: false,
                		nav: true,
                		dots: false,
                        items: 4,
                        center: false,
                		avRewind: false,
                		lazyLoad: true,
                		center: false,
                		autoplay: false,
                        margin: 0,
                		autoplayTimeout: 5000,
                		autoplayHoverPause: false,
                		smartSpeed: 1000,
                		responsive: {
                			0: {
                				items: 2
                            },
                			560: {
                				items: 3
                			},
                			992: {
                				items: 4
                			}
                        }
                	});
                }

                // carrusel bloque testimonios
                if ($('.js-carousel-testimonials').length) {

                    $('.js-carousel-testimonials').owlCarousel({
                        loop: true,
                        nav: true,
                        items: 1,
                        avRewind: false,
                        lazyLoad: true,
                        center: true,
                        autoplay: true,
                        margin: 0,
                        autoplayTimeout: 5000,
                        autoplayHoverPause: false,
                        smartSpeed : 1000,
                    })
                }

                // carrusel bloque gallery
                if($('.js-carousel-gallery').length) {
                    $('.js-carousel-gallery').owlCarousel({
                		loop: true,
                		nav: true,
                		dots: false,
                        items: 4,
                        center: false,
                		avRewind: false,
                		lazyLoad: true,
                		center: false,
                		autoplay: false,
                        //margin: 20,
                        margin: 0,
                		autoplayTimeout: 5000,
                		autoplayHoverPause: false,
                		smartSpeed: 1000,
                		responsive: {
                			0: {
                				items: 2
                            },
                			560: {
                				items: 3
                			},
                			992: {
                				items: 4
                			}
                        }
                	});
                }

                // carrusel productos
                var $jsOwlProducts = $('.js-owl-products');
                if($jsOwlProducts.length) {
                    //console.log('hay productos');
                    $jsOwlProducts.owlCarousel({
                        loop: true,
                        nav: true,
                        dots: false,
                        center: false,
                        items: 3,
                        margin: 0,
                        autoplayTimeout: 5000,
                        autoplayHoverPause: false,
                        smartSpeed : 1000,
                        responsive: {
                            0: {
                                items: 1
                            },
                            600: {
                                items: 2
                            },
                            1000: {
                                items: 3
                            }
                        }
                    });
                }


                // carrusel detalle producto
                var $jsSlightProductDetail = $('#js-lightSlider-product-detail');
                if($jsSlightProductDetail.length) {
                    $jsSlightProductDetail.lightSlider({
                        gallery: true,
                        item: 1,
                        loop: false,
                        slideMargin: 0,
                        galleryMargin: 30,
                        thumbItem: 3,
                        thumbMargin: 10,
                        enableDrag: false,
                        onSliderLoad: function(el) {

                            $( "li" ).append('<div class="mask"></div>');
                            $( "#js-lightSlider-product-detail .mask" ).remove();

                            el.lightGallery({
                                selector: '.lslide'
                            });
                        }
                    });
                }



            }
        };
    }


    return {

        getInstance: function(){

            if (!instance){
                instance = init();
            }

            return instance;
        }

    };


})();



/* jshint ignore:start */

var FormsManager = (function() {
    var instance;



    function init() {

        var _conversionContactLabel;
        var _conversionPhoneLabel;

        function resetForm($form) {
            $form.find('.form-group').removeClass('has-error').removeClass('has-success');
            $form.find('.js-btn').attr('disabled', false);

            var code = $form.data('code');

            switch (code) {
                case 'contact':
                    jQuery('#name').val('');
                    jQuery('#email').val('');
                    jQuery('#message').val('');
                    break;

                case 'contact-phone-email':
                    jQuery('#banner-email').val('');
                    jQuery('#banner-phone').val('');
                    break;

                default:
                    break;
            }
        }

        function checkRedirection($form,code)
        {
            switch (code) {
                // case 'contact':
                //     jQuery('.js-preloader-layer').append('<div class="layer-preloader"></div>');
                //
                //     var redirection = $form.data('redirection');
                //     window.location = redirection;
                //
                //
                //     return true;

                default: return false;
            }
        }

        function getFormRules(codeForm)
        {

            switch (codeForm) {
                case 'contact':
                    return {
                        'name': { required: true },
                        'email': { required: true, email: true },
                        'message': { required: true }
                    };

                case 'contact-phone-email':
                    return {
                        'banner-email': { required: {
                            depends: function(element){
                                    return jQuery('#banner-phone').val() == '';
                                }
                            },
                            email: true },
                        'banner-phone': { required: {
                                depends: function(element){
                                    return jQuery('#banner-email').val() == '';
                                }
                            },
                            digits: true,
                            min: 9 }
                    };

                default:
                    return {
                        'name': { required: true },
                        'email': { required: true, email: true }
                    };
            }

        }



        return {
            checkAndInit: function()
            {
                this.initForms();
            },

            initForms: function()
            {
                var ref = this;


                if (jQuery('.js-form').length) {

                    jQuery('.js-form').each(function () {
                        ref.initForm(jQuery(this));
                    });
                }
            },

            initForm: function($form) {
                var ref = this;

                var code = $form.data('code');

                $form.validate({
                    event: "blur",
                    rules: getFormRules(code),
                    validClass: "has-success",
                    errorClass: "has-error",
                    highlight: function(element, errorClass, validClass){
                        if (code !== 'contact') {
                            var $parent = jQuery(element).closest(".form-group");
                        } else {
                            var $parent = jQuery(element).closest(".form-item");
                        }
                        $parent.addClass(errorClass).removeClass(validClass);
                    },
                    unhighlight: function(element, errorClass, validClass){
                        var $element = jQuery(element);
                        if (code !== 'contact') {
                            var $parent = $element.closest(".form-group");
                        } else {
                            var $parent = $element.closest(".form-item");
                        }
                        $parent.removeClass(errorClass).addClass(validClass);

                    },
                    errorPlacement: function(error, element) {
                        var $message = $form.find('.js-form-message');
                        $message.removeClass('has-error').removeClass('has-success');
                        $message.addClass('has-error');
                        $message.html('Revisa los campos y vuelve a intentarlo.');
                    },
                    submitHandler: function(form) {
                        var $myform = jQuery(form);

                        $myform.find('.js-btn').attr('disabled', true);
                        ref.sendForm($myform);
                    }
                });



            },

            sendForm: function($form)
            {
                var ref = this;

                var $message = $form.find('.js-form-message');
                $message.removeClass('has-error').removeClass('has-success');
                $message.html('');

                $form.find('.js-preloader-layer').append('<div class="layer-preloader"></div>');

                jQuery.ajax({
                    type: "POST",
                    dataType: 'json',
                    url: $form.data('url'),
                    contentType: "application/x-www-form-urlencoded",
                    processData: false,
                    data: $form.serialize(),
                    success: function(response){
                        // console.log(response);
                        ref.onResultForm(response, $form);
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        var data = JSON.parse(jqXHR.responseText);
                        ref.onErrorForm(data, $form);
                    }
                });
            },

            onResultForm: function(data, $form)
            {
                var $layerResult = $form.find('.js-preloader-layer');
                $layerResult.find('.layer-preloader').remove();

                $layerResult.addClass('no-padding-top');


                var $message = $form.find('.js-form-message');
                $message.removeClass('has-error').removeClass('has-success');

                if (!data.ERROR && data.status === 'OK') {

                    var code = $form.data('code');

                    if (!checkRedirection($form,code)) {
                        $message.addClass('has-success');
                        $message.html(data.message);
                    }


                } else {
                    $message.addClass('has-error');
                    $message.html(data.message);
                }

                TweenMax.to($message, 0.6, { opacity:0, ease:Power2.easeInOut, delay:5, onComplete:function(){
                    $message.removeClass('has-error').removeClass('has-success');
                    $message.html('');
                    $layerResult.removeClass('no-padding-top');

                    resetForm($form);

                    TweenMax.to($message, 0.2, { opacity:1 });
                }});
            },

            onErrorForm: function(data, $form)
            {
                var $message = $form.find('js-form-message');
                $message.removeClass('has-error').removeClass('has-success');

                $message.addClass('has-error');
                $message.html('Ha ocurrido un error');
            }
        };
    }


    return {

        getInstance: function(){

            if (!instance){
                instance = init();
            }

            return instance;
        }

    };


})();
/* jshint ignore:end */
/**
 *
 * Gestion de literales que son usadas en javascript
 *
 */

var LabelsManager = (function(){
    //// console.log("llamada a config idioma");
    var instance;


    function init() {

        return {
            /*LABEL_CANTIDAD : LABEL_CANTIDAD,*/
        };
    }


    return {

        getInstance: function(){

            if (!instance){
                instance = init();
            }

            return instance;
        }

    };


})();



/**
 *
 * Gestion de mapas
 *
 */

var MapsManager = (function() {
    var instance;



    function init() {

        return {
            checkAndInit: function()
            {
                this.initMaps();
            },

            initMaps: function()
            {
                var ref = this;

                if (jQuery('.js-googleMap').length) {

                    jQuery('.js-googleMap').each(function () {
                        ref.initMap(jQuery(this));
                    });
                }
            },

            initMap: function($map) {
                var ref = this;

                var zoom = $map.data('zoom');
                var latitude = $map.data('latitude');
                var longitude = $map.data('longitude');
                var icon = $map.data('icon');
                var idMap = $map.attr('id');


                var map = new google.maps.Map(document.getElementById(idMap), {
                    center: {lat: latitude, lng: longitude},
                    scrollwheel: false,
                    zoom: zoom
                });

                var point = {lat: latitude, lng: longitude, icon: icon};
                this.initMarker(map, point);

            },

            initMarker: function(map, point){
                var ref = this;
                var latlng = new google.maps.LatLng(point.lat, point.lng);

                var marker = new google.maps.Marker({
                    position: latlng,
                    map: map,
                    title: point.name,
                    icon: point.icon
                    // html: getHtmlMap(point)
                });

                // marker.addListener('click', function() {
                //     var previousZoom = _map.getZoom();
                //
                //     if (_map.getZoom() != point.zoom) {
                //         _map.setZoom(point.zoom);
                //     } else {
                //         _info_window.setOptions({ maxWidth: 500 });
                //         _info_window.setContent(this.html);
                //         _info_window.open(_map, this);
                //     }
                //     _map.setCenter(marker.getPosition());
                //
                //     ref.showShop(_points[marker.idx]);
                // });
                //
                // _points[point.idx].marker = marker;
                //
                // _markers.push(marker);
            },
        };
    }


    return {

        getInstance: function(){

            if (!instance){
                instance = init();
            }

            return instance;
        }

    };


})();
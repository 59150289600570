/**
 *
 * Gestion de faqs
 *
 */

var FaqsManager = (function(){
    //// console.log("llamada a config idioma");
    var instance;


    function init() {

        return {
            checkAndInit: function(){
                if ($('.faqs').length) {
                    $(document).on('click', '.faqs a', function (ev) {
                        ev.preventDefault();
                        var $detail = $(this).parents('li:eq(0)').find('.detail');
                        //$(this).text($detail.is(':visible') ? '+' : '-').toggleClass('shown');
                        $(this).toggleClass('shown');
                        $detail.slideToggle();
                    });
                }

            }
        };
    }


    return {

        getInstance: function(){

            if (!instance){
                instance = init();
            }

            return instance;
        }

    };


})();



/* jshint ignore:start */

var MenuManager = (function(){
    //// console.log("llamada a config idioma");
    var instance;


    function init() {
        var $header = $('.header ');

        function activateHeaderBackground() {
            $('.header').addClass('header--opaque');
            $('.header--transparent').addClass('header--hover');
        };

        function deactivateHeaderBackground() {
            var scroll = $(window).scrollTop();

            $header.removeClass('header--shadow');
            $header.removeClass('header--opaque');
            if ( scroll < 100 ) {
                $('.header--transparent').removeClass('header--hover');
            }
        };

        return {
            initCommon: function()
            {
                var hasScroll = false;

                $header.hover(function() {
                    if ($(window).width() > 768) {
                        $(this).addClass('header--hover');
                    }
                }, function(){
                    $(this).removeClass('header--hover');
                });

                $(window).scroll(function (event) {
                    var scroll = $(window).scrollTop();
                    if (scroll > 100) {
                        hasScroll = true;
                        $header.addClass('header--shadow');
                    } else {
                        hasScroll = false;
                        $header.removeClass('header--shadow');
                    }
                });
            },

            initMobile: function()
            {
                var $document = $(document);
                var $mobile_link_arr = $('.js-mobile-link');
                var $desktop_link_arr = $('.js-desktop-link');

                // menu movil
                $document.on("click", ".mobile-ico-menu a", function(ev) {
                    ev.preventDefault();
                    activateHeaderBackground();

                    $(".layer-menu-mobile").addClass("active");
                    // $('body').addClass('header--open').addClass('no_scroll-body');
                    $('body').addClass('header--open');

                });

                // buscador movil
                $document.on("click", ".header__search-btn a", function(ev) {
                    ev.preventDefault();
                    activateHeaderBackground();

                    $(".mobile-search").addClass("active");
                    $('body').addClass('header--open');
                    $('.mobile-search .search-field').focus();
                });

                // abrir y cerrar submenu
                $document.on("click", ".menu-mobile a.js-opt-subopt", function(ev) {
                    ev.preventDefault();
                    $(".js-opt-subopt").not(this).parent().removeClass("open");
                    $(this).parent().toggleClass("open");
                });

                // marcar como seleccionado la sección actual
                $desktop_link_arr.each(function(){
                    if( global_uri.includes($(this).attr('href')) ) {
                        $(this).closest('li').addClass('selected').closest('.desktop-menu').addClass('selected');
                    }
                });

                // mobile -> abrir submenu actual y marcar como seleccionado
                $mobile_link_arr.each(function(){
                    if( global_uri.includes($(this).attr('href')) ) {
                        $(this).closest('li').addClass('selected').closest('.item-sub').addClass('open');
                    }
                });

                // boton cerrar, buscador y menu movil
                $document.on("click", ".close-btn", function(ev) {
                    ev.preventDefault();
                    deactivateHeaderBackground();

                    $(".mobile-search").removeClass("active");
                    $(".layer-menu-mobile").removeClass("active");
                    $('body').removeClass('header--open').removeClass('no_scroll-body');

                    return false;
                });
            },

            initDesktop: function()
            {
                $(document).on('click', '#header__search-btn-open, #header__search-btn-close', function(ev) {
                    $("#header__search").toggleClass("active");
                    $('.header__search-btn').toggleClass('open');
                    $('#header__search .search-field').focus();
                    ev.preventDefault();
                });
            },



            init: function(){
                this.initCommon();
                this.initMobile();
                this.initDesktop();
            },
        };
    }


    return {

        getInstance: function(){

            if (!instance){
                instance = init();
            }

            return instance;
        }

    };


})();
/* jshint ignore:end */

